.swiper-container {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
  }
  
  .coin-slide {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    background-color: #000;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .coin-card {
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: 100vh;
  background-color: #1e1e1e;
  border-radius: 20px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  color: white;
  padding-bottom: 60px;

  }
  
  .coin-image {
    width: 100%;
    height: 20vh; /* Fixed height */
    object-fit: cover;
    flex-shrink: 0; /* Prevent image from shrinking */
}
  
  .coin-info {
    padding: 20px;
    flex-grow: 1;
    overflow-y: visible;
    max-height: calc(90vh - 60% - 60px); /* Adjust based on image height and action buttons */

  }
  
  .coin-info h2 {
    margin: 0 0 10px 0;
    font-size: 24px;
  }
  
  .coin-info p {
    margin: 0;
    font-size: 16px;
  }
  
  .coin-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
  }
  
  .action-button {
    padding: 10px;
  font-size: 18px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.2s;
  }
  
  .action-button:hover {
    transform: scale(1.2);
  }
  .action-button.favorite.active {
    color: red;
  }

  .pump-fun-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .pump-fun-link:hover {
    text-decoration: underline;
  }


  

