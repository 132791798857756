.xrp-teaser-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    color: #ffffff;
    background: #0f172a;
  }
  
  .xrp-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .xrp-header h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, #00ff88, #00c2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #94a3b8;
  }
  
  .xrp-section {
    margin-bottom: 4rem;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
  }
  
  .xrp-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #00ff88;
  }
  
  .token-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }
  
  .token-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .token-card:hover {
    transform: translateY(-5px);
  }
  
  .coming-soon-btn {
    background: linear-gradient(45deg, #00ff88, #00c2ff);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    color: #0f172a;
    font-weight: bold;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .coming-soon-btn:hover {
    opacity: 0.9;
  }
  
  .resources-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .resources-list a {
    color: #00c2ff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .resources-list a:hover {
    color: #00ff88;
  }
  
  .roadmap-timeline {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .roadmap-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 0.5rem;
  }
  
  .roadmap-item h3 {
    color: #00ff88;
    margin-bottom: 1rem;
  }
  
  .roadmap-item ul {
    list-style: none;
    padding: 0;
  }
  
  .roadmap-item li {
    margin-bottom: 0.5rem;
    color: #94a3b8;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 0.5rem;
  }
  
  .feature-card h3 {
    color: #00ff88;
    margin-bottom: 1rem;
  }
  
  .feature-card p {
    color: #94a3b8;
  }
  
  .cta-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .primary-btn, .secondary-btn {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 0.3s ease;
  }
  
  .primary-btn {
    background: linear-gradient(45deg, #00ff88, #00c2ff);
    color: #0f172a;
  }
  
  .secondary-btn {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
  
  .primary-btn:hover, .secondary-btn:hover {
    opacity: 0.9;
  }
  
  .xrp-footer {
    text-align: center;
    margin-top: 4rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .social-links a {
    color: #94a3b8;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-links a:hover {
    color: #00ff88;
  }
  
  @media (max-width: 768px) {
    .xrp-header h1 {
      font-size: 2rem;
    }
    
    .cta-buttons {
      flex-direction: column;
    }
    
    .xrp-section {
      padding: 1rem;
    }
  }