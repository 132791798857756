.how-it-works-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: rgba(50, 46, 41, 0.95); /* #322E29 with transparency */
  color: #FEFFEB;
    border-radius: 20px;
  }
  
  .how-it-works-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .how-it-works-header h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    background: linear-gradient(45deg, #C59E57, #9B7940);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .subtitle {
    font-size: 1.2rem;
    opacity: 0.9;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .steps-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .step-card {
    background: rgba(66, 61, 54, 0.95); /* #423D36 with transparency */
  border: 1px solid rgba(197, 158, 87, 0.2); /* #C59E57 with transparency */
    border-radius: 16px;
    padding: 2rem;
    position: relative;
    backdrop-filter: blur(10px);
   
    transition: transform 0.3s ease;
  }
  
  .step-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .step-number {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40px;
    height: 40px;
    background: #C59E57;
    color: #FEFFEB;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .step-content h3 {
    margin-bottom: 1rem;
    color: #C59E57;;
    font-size: 1.3rem;
  }
  
  .step-content p {
    margin-bottom: 1rem;
    opacity: 0.9;
  }
  
  .step-content ul {
    list-style: none;
    padding: 0;
  }
  
  .step-content li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .step-content li:before {
    content: "•";
    color: #C59E57;
    position: absolute;
    left: 0;
  }
  
  .cta-section {
    text-align: center;
    padding: 3rem;
    background: rgba(76, 175, 80, 0.1);
    border-radius: 16px;
    margin-top: 2rem;
  }
  
  .create-agent-button {
    background: #C59E57;
  color: #FEFFEB;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
  }
  
  .create-agent-button:hover {
    background: #9B7940;
  box-shadow: 0 5px 15px rgba(197, 158, 87, 0.3);
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .how-it-works-container {
      padding: 1rem;
    }
  
    .how-it-works-header h1 {
      font-size: 2rem;
      color: white;
    }
  
    .steps-container {
      grid-template-columns: 1fr;
    }
  
    .step-card {
      margin-top: 2rem;
    }
  }