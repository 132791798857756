.settings-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .settings-modal {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    color: white;
  }
  
  .settings-modal h2 {
    margin-top: 0;
  }
  
  .settings-modal select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .close-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .close-button:hover {
    background-color: #45a049;
  }