.create-agent-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
}

.custom-traits {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

.token-input {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;
}

.token-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.token-chip {
  margin: 0.25rem;
}

.trading-settings {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

.submit-button {
  margin-top: 2rem;
}