.favorites-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .favorites-modal {
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    color: black;
  }
  
  .favorites-list {
    list-style-type: none;
    padding: 0;
  }
  
  .favorite-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #2a2a2a;
    border-radius: 5px;
  }
  
  .favorite-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
    margin-right: 15px;
  }
  
  .favorite-info {
    flex-grow: 1;
  }
  
  .favorite-info h3 {
    margin: 0 0 5px 0;
  }
  
  .favorite-info p {
    margin: 0;
    font-size: 14px;
  }
  
  .favorite-actions button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .favorite-actions button:hover {
    background-color: #45a049;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab-button {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .tab-button.active {
    background-color: #007bff;
    color: white;
  }
  

  
  .tokens-list {
    list-style-type: none;
    padding: 0;
  }
  
  .token-item {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    
  }
  
  .token-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .token-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .token-title h3 {
    margin: 0;
    font-size: 18px;
    color: black;
  }
  
  .token-info {
    flex: 1;
  }
  
  .pump-fun-link {
    font-size: 12px;
    color: #007bff;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  
  .pump-fun-link:hover {
    text-decoration: underline;
  }
  
  .token-body {
    margin-bottom: 12px;
  }
  
  .token-description {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 8px;
  }
  
  .token-stats {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #495057;
  }
  
  .token-actions {
    display: flex;
    align-items: center;
  }
  
  .sell-button {
    display: flex;
    align-items: center;
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .sell-button:hover {
    background-color: #c82333;
  }
  
  .button-icon {
    margin-right: 8px;
  }
  
  .sell-percentage {
    margin-left: 12px;
  }
  
  .sell-percentage-select {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .loading-message,
  .wallet-message,
  .no-tokens-message {
    text-align: center;
    font-size: 16px;
    color: #6c757d;
    padding: 20px;
  }
  
  .close-button {
    margin-top: 20px;
  }

  .favorites-button {
    position: fixed;
    bottom: 20px;
    right: 60px; /* Adjust this value to position it next to the settings button */
    z-index: 1000;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .favorites-button:hover {
    transform: scale(1.2);
  }