.agent-dashboard {
    max-width: 100%;
    background: linear-gradient(180deg, #322E29 0%, #423D36 100%);
    color: #FEFFEB;
    min-height: 100vh;
  }
  
  .dashboard-nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
    background: rgba(66, 61, 54, 0.95);
    padding: 1rem;
    border-radius: 12px;
    backdrop-filter: blur(10px);
  }
  
  .nav-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: rgba(197, 158, 87, 0.1);
    color: #FEFFEB;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .nav-button.active {
    background: #C59E57;
    box-shadow: 0 0 20px rgba(197, 158, 87, 0.3);
  }
  
  .agents-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }
  
  .agent-card {
    background: rgba(66, 61, 54, 0.95);
  border: 1px solid rgba(197, 158, 87, 0.2);
    border-radius: 16px;
    padding: 1.5rem;
    backdrop-filter: blur(10px);
  }
  
  .agent-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  
  .agent-stats {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    font-size: 0.9rem;
  }
  
  .performance {
    color: green;
    font-weight: bold;
  }
  
  .agent-creator {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .loading-spinner {
    text-align: center;
    color: white;
    padding: 2rem;
  }

  .nav-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .nav-button:hover {
    transform: scale(1.05);
  }
  
  .nav-button:active {
    transform: scale(0.95);
  }
  
  .agent-card {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    padding: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
    transform: translateY(0);
    color: rgba(254, 255, 235, 0.6); /* #FEFFEB with transparency */

  }
  
  .agent-card:hover {
    transform: scale(1.02);
  }
  
  /* Add animation for new cards */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .agent-card {
    animation: fadeInUp 0.3s ease;
  }