.agent-tester {
    padding: 2rem;
    background: rgba(66, 61, 54, 0.95);
    border-radius: 16px;
    color: #FEFFEB;
  }
  
  .test-button {
    background: #C59E57;
    color: #FEFFEB;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .test-button:disabled {
    background: #9B7940;
    cursor: not-allowed;
  }
  
  .test-results {
    background: rgba(50, 46, 41, 0.95);
    padding: 1.5rem;
    border-radius: 12px;
    margin-top: 1rem;
  }
  
  .result-section {
    margin-bottom: 1.5rem;
  }
  
  .result-section h4 {
    color: #C59E57;
    margin-bottom: 0.75rem;
  }
  
  .result-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background: rgba(66, 61, 54, 0.95);
    border-radius: 8px;
    margin-bottom: 0.5rem;
  }
  
  .result-item span {
    font-size: 0.9rem;
  }