.xrp-dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .token-card {
    background: #1a1a1a;
    border: 1px solid #333;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .token-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .token-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .token-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #333;
  }
  
  .token-name {
    font-size: 24px;
    color: #fff;
    margin: 0;
  }
  
  .token-symbol {
    color: #888;
    margin: 5px 0 0 0;
  }
  
  .social-links {
    display: flex;
    gap: 10px;
  }
  
  .social-link {
    color: #888;
    text-decoration: none;
    font-size: 20px;
    transition: color 0.3s ease;
  }
  
  .social-link:hover {
    color: #4a9eff;
  }
  
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .metrics-card {
    background: #252525;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #333;
  }
  
  .metrics-label {
    color: #888;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .metrics-value {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  
  .metrics-change {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .metrics-change.positive { color: #4caf50; }
  .metrics-change.negative { color: #f44336; }
  
  .trading-info {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .transactions-card, .price-changes {
    background: #252525;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #333;
  }
  
  .card-title {
    color: #888;
    margin: 0 0 10px 0;
    font-size: 16px;
  }
  
  .transactions-data {
    display: flex;
    justify-content: space-between;
  }
  
  .buys { color: #4caf50; }
  .sells { color: #f44336; }
  
  .timeframes {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    text-align: center;
  }
  
  .period {
    color: #888;
    font-size: 14px;
  }
  
  .change {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .change.positive { color: #4caf50; }
  .change.negative { color: #f44336; }
  
  .website-link {
    padding-top: 15px;
    border-top: 1px solid #333;
  }
  
  .website-button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    background: #252525;
    color: #4a9eff;
    padding: 8px 15px;
    border-radius: 6px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .website-button:hover {
    background: #333;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #333;
    border-top: 4px solid #4a9eff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .error-message {
    color: #f44336;
    text-align: center;
    padding: 20px;
    background: rgba(244, 67, 54, 0.1);
    border-radius: 8px;
    margin: 20px 0;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .trading-info {
      grid-template-columns: 1fr;
    }
    
    .token-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    
    .social-links {
      align-self: flex-start;
    }
  }
  
  .dashboard-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    justify-content: center;
  }
  
  .action-button {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    font-size: 1rem;
  }
  
  .telegram-button {
    background-color: #0088cc;
    color: white;
  }
  
  .donate-button {
    background-color: #ff4b81;
    color: white;
  }
  
  .action-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }
  
  .donate-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .donate-modal {
    background-color: black;
    padding: 2rem;
    border-radius: 12px;
    max-width: 90%;
    width: 400px;
    position: relative;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
  }
  
  .qr-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
  
  .qr-container img {
    max-width: 200px;
    height: auto;
  }
  
  .address-container {
    margin-top: 1rem;
  }
  
  .address-box {
    display: flex;
    align-items: center;
    background-color: #1a1a1a;
    padding: 0.5rem;
    border-radius: 6px;
    margin-top: 0.5rem;
  }
  
  .address-box code {
    flex: 1;
    word-break: break-all;
  }
  
  .copy-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    margin-left: 10px;
  }
  
  .copy-button:hover {
    opacity: 0.7;
  }