.App {
  text-align: center;
  background-color: black;
}



/* Reset any conflicting styles */
.app-logo {
  height: 50vmin;  /* Increased from 40vmin */
  max-height: 300px; /* Prevents logo from getting too large on big screens */
  min-height: 300px; /* Ensures logo doesn't get too small on mobile */
  pointer-events: none;
  margin: 20px 0; /* Adds some vertical spacing */
  width: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures the logo fits properly */
}

/* Override any MUI styles */
.app-logo.css-zkh2jo {
  width: auto !important;
  height: 50vmin !important;
  max-height: 300px !important;
  min-height: 300px !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .app-logo,
  .app-logo.css-zkh2jo {
    height: 45vmin !important; /* Slightly smaller on mobile */
    min-height: 150px !important;
  }
}

/* Optional: Add bounce animation */
@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-bounce 2s ease-in-out infinite;
  }
}

@keyframes App-logo-bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-indicator {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: white;
  writing-mode: vertical-rl;
  text-orientation: upright;
  z-index: 1000;
}

.arrow {
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
  animation: bounce-vertical 1s infinite;
}

@keyframes bounce-vertical {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.settings-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.settings-button:hover {
  transform: scale(1.2);
}



.app-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  overflow-y: auto;
  width: 100%;
}

.clear-tokens-button {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 1000;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}
.clear-tokens-button:hover {
  background-color: #d32f2f;
}
.token-control-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 10px 0;
}

.fetch-tokens-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.fetch-tokens-button:hover {
  background-color: #45a049;
}
.fetch-tokens-button:active {
  transform: scale(0.98);
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.latest-mint-address {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.side-icons {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  color: white;
}

.icon-button {
  background: none;
  border: none;
  font-size: 24px;
  margin: 10px 0;
  cursor: pointer;
  color: white;
}

.icon-button:hover {
  transform: scale(1.1);
}


.auto-snipe-container {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 1000;
}




.auto-snipe-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.auto-snipe-button:hover {
  background-color: #45a049;
}

.auto-snipe-button.enabled {
  background-color: #f44336;
}

.auto-snipe-button.shake {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.app-logo {
  height: 80px; /* Adjust as needed */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.favorites-button {
  position: fixed;
  bottom: 20px;
  right: 60px; /* Adjust this value to position it next to the settings button */
  z-index: 1000;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.favorites-button:hover {
  transform: scale(1.2);
}


.wallet-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.wallet-button:hover {
  background-color: #45a049;
}


.button-row {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.settings-button,
.favorites-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
  
}

.settings-button:hover,
.favorites-button:hover {
  transform: scale(1.2);
}

.disclaimer {
  font-size: 0.9em;
  color: #ff9900;
  background-color: rgba(255, 153, 0, 0.1);
  border: 1px solid #ff9900;
  border-radius: 4px;
  padding: 5px 10px;
  margin-top: 10px;
  text-align: center;
}

.test-fee-button {
  background: #2c3e50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.test-fee-button:hover {
  background: #34495e;
}

.test-fee-button:active {
  transform: scale(0.98);
}